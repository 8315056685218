.react-datepicker {
  display: flex !important;
  gap: 20px;
}

.request-table tr:hover {
  background-color: #f5f5f5 !important;
}

.request-table thead tr:hover {
  background-color: white !important;
}

.request-table {
  width: 100%; /* Ensures the table takes up the full width */
  margin: 0 auto;
  overflow: auto;
  border-collapse: collapse; /* Improves table appearance */
}

.request-table th,
.request-table td {
  padding: 8px 12px;
  text-align: left;
}

.table-request tbody tr:hover {
  cursor: pointer;
  background-color: #b4b8a1 !important;
}
tbody tr:hover {
  cursor: pointer;
  background-color: #b4b8a1 !important;
}

.request-table th {
  background-color: #f4f4f4; /* Light background for header */
}

/* .request-table tr:hover {
  background-color: #f5f5f5;
} */

.request-table td {
  white-space: nowrap; /* Prevents content from wrapping */
}

.admin-btns {
  display: flex;
  gap: 5px;
  color: black;
  display: block;
}

.admin-btns button {
  margin: 1px;
  border-radius: 7px;
  padding: 4px 7px;
  font-size: 14px;
  cursor: pointer;
  background-color: #2d3657;
  color: white;
  border: 1px solid var(--main--color) !important;
}
.admin-btns button:hover {
  background-color: #ebc72b;
  color: white;
}

.request-table tr:hover {
  opacity: 1;
}
.pdfviewermodal {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.appointments-container {
  padding: 20px;
}

.date-picker-container {
  margin-bottom: 10px;
}

.time-slots {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(60px, 1fr)
  ); /* Smaller min-width */
  gap: 5px; /* Reduced gap */
}

.time-slot {
  position: relative;
  padding: 5px; /* Smaller padding */
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  font-size: 10px; /* Smaller font size */
  background-color: #d4edda;
  color: #155724;
  transition: background-color 0.3s;
}

.time-slot.booked {
  background-color: #f8d7da;
  color: #721c24;
}
.file-icon:hover {
  color: #ebc72b;
}