.custom-btn {
  background-color: black;
  color: white;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 100px !important;
}

.custom-btn:hover {
  background-color: #333;
}

.containercontact {
  width: 70%;
  height: 60%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 25px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.contactmain {
  background: linear-gradient(
      to left,
      rgba(3, 83, 128, 0.63) 50%,
      rgba(3, 83, 128, 0.63) 50%
    ),
    url(../assets/homebg.webp);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%; /* Full height */
  margin: 0 !important;
}
.contactcontents {
  margin-top: 5%;
}

@media (max-width: 768px) {
  .contactmain {
    padding: 10px;
  }

  .containercontact {
    width: 90%;
    height: 80%;
    padding: 20px;
  }

  .col-md-4 {
    margin-bottom: 20px;
  }

  .custom-btn {
    margin-top: 20px;
  }
  .contactcontents {
    margin-top: 1%;
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .contactmain {
    padding: 10px;
    height: 80% !important;
  }

  .containercontact {
    width: 90%;
    height: auto;
    padding: 30px;
  }
}

@media (min-width: 480px) and (max-width: 960px) {
  .contactmain {
    padding: 10px;
    height: 700px !important;
  }

  .containercontact {
    width: 100%;
    height: auto;
    padding: 30px;
  }

  .col-md-4 {
    margin-bottom: 25px;
  }

  .custom-btn {
    margin-top: 20px;
  }

  .contactcontents {
    margin-top: 3%;
  }

  .form-control {
    font-size: 16px;
  }
}
