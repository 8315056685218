* {
  margin: 0;
  padding: 0;
}
:root {
  --main-color: #2d3657;
  --text-color: #00242e;
  --border-color: #00000029;
  --white-color: #ffffff;
  --green-color: #06a900;
  --blue-color: #a7c6d8;
  --brown-color: #858b67;
  --orange-color: #ebc72b;
  --light-orange-color: #fee98e;
}

.main_color_class {
  color: #2d3657;
}

.text_color_class {
  color: var(--text-color);
}

.border_color_class {
  border-color: var(--border-color);
}

.white_color_class {
  color: var(--white-color);
}

.green_color_class {
  color: var(--green-color);
}

.homebgimage img {
  width: 100%;
  opacity: 0.9;
}

.header {
  text-align: center;
  letter-spacing: 0px;
  color: var(--main-color);
  font-size: 37px;
  opacity: 1;
  font-weight: 800;
  line-height: 45.1px;
}

.home-search-input {
  width: 488px;
  height: 58px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 5px;
  opacity: 1;
  border: none;
  padding: 1rem;
}

.home-button {
  width: 120px;
  height: 30px;
  background: var(--main-color) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
}

.header-right {
  margin-left: 100px;
  position: relative;
  padding: 10px 20px;
}

.header-right::before {
  content: "";
  position: absolute;
  top: 50px; /* Üstten 50px boşluk */
  left: -10px; /* Soldan 10px boşluk */
  right: -10px; /* Sağdan 10px boşluk */
  bottom: 50px; /* Alttan 50px boşluk */
  background: transparent; /* Arka plan rengini şeffaf yapar */
  z-index: -1; /* Kartın başlık ve inputların arkasında olmasını sağlar */
  border-radius: 10px; /* Köşe yuvarlama */
  padding: 40px; /* Kartın içindeki boşluk */
  box-sizing: border-box; /* Padding ve border'ı toplam yüksekliğe dahil eder */
  box-shadow: 0 0 15px #858b67; /* Aynalama efekti */
}

.header-right h3 {
  font-size: 23px;
  line-height: 36px;
}
.headerbgright {
  background-color: #a7c6d86e;
}
.headerleftbox {
  width: 500px;
  height: 67px;
  border-radius: 20px;
  background-color: #f1f1f1;
}
.headerleftbox label,
input {
  margin-left: 10px;
  margin-right: 10px;
}
.checkbox-round {
  margin-left: 10px !important;
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: var(--main-color);
}

.headerbottom {
  height: 140px;
  width: 100%;
}
.headerbottomleft {
  background-color: #a7c6d86e;
  padding: 20px 100px;
  width: 76.4%;
}
.headerbottomright {
  padding: 20px 100px;
  width: 76.5%;
}
.roundedsection img {
  height: 93px;
  width: 93px;
  border-radius: 50%;
  opacity: 0.9;
}
.fa-cube {
  left: 20px;
  top: 20px;
  font-size: 55px;
  color: rgb(247, 242, 242);
}
.fa-regular {
  left: 20px;
  top: 20px;
  font-size: 50px;
  color: var(--main-color);
}

.headerbottomright {
  /* background-image: url(../assets/homebg.webp); */
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url(../assets/headerbottom.png);

  background-size: cover;
}

.headerleftbox {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.headerleftbox:hover {
  background: var(--light-orange-color);
}
.no-wrap {
  white-space: nowrap;
}

.headerleftbox label {
  cursor: pointer;
}
.headertitleimg {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  line-height: 44px;
  font-weight: 800;
  color: white;
  width: 80%;
  z-index: 2;
}

.homebgimage {
  position: relative;
  width: 50%;
}

.homebgimage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  opacity: 0.6;
  z-index: 1;
}

.selected {
  background-color: var(--orange-color) !important;
  text-decoration: none !important;
  color: var(--main-color) !important;
}
.table-headerright {
  color: var(--main-color);
}
@media (max-width: 480px) {
  .header-right::before {
    top: 20px;
    left: -90px;
    right: 8px;
    bottom: 20px;
  }
  .header-right h3 {
    font-size: 19px;
    line-height: 25px;
    margin-top: 30px;
  }
  .contain .headermain {
    display: flex !important;
    flex-direction: column !important;
  }
  .homebgimage img {
    width: 407px !important;
    height: 300px !important;
  }
  .header {
    font-size: 20px;
    text-align: center;
    margin: 20px 0 0px -90px;
    line-height: 30px;
  }
  .table-headerright {
    margin: 0 0 20px -95px;
  }
  .headerleftbox {
    width: 330px;
    height: 70px;
  }
  .checkbox-round {
    width: 30px;
    height: 19px;
    border-radius: 50%;
  }
  .headerbottom {
    width: 100% !important;
    flex-direction: column;
  }
  .headerbottomleft,
  .headerbottomright {
    width: 100% !important;
    gap: 2rem;
    height: 150px;
  }

  .contain {
    height: 1000px !important;
  }
  .roundedsection {
    margin-left: -10px;
  }

  .mobiledes h5 {
    font-size: 21px !important;
    margin-top: -50px;
    width: 200px;
  }
  .mobiledes p {
    font-size: 15px !important;
    width: 190px;
  }
  .headertitleimg {
    width: 360px !important;
    margin-left: 90px;
  }
  .homebgimage::before {
    width: 407px !important;
  }
}
/* Base styles (for screens wider than 960px) */
@media (min-width: 480px) and (max-width: 960px) {
  .contain .headermain {
    display: flex;
    flex-direction: row;
  }
  .header-right::before {
    top: 10px;
    left: 2px;
    right: 8px;
    bottom: 6px;
  }
  .header-right h3 {
    font-size: 20px !important;
    line-height: 25px !important;
    margin-top: 20px !important;
  }
  .homebgimage img {
    width: 100%;
    height: auto;
  }
  .homebgimage::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    );
    opacity: 0.6;
    z-index: 1;
  }

  .header {
    font-size: 37px;
    text-align: center;
    margin: 0;
    line-height: 45.1px;
  }

  .table-headerright {
    margin: 0;
  }

  .headerleftbox {
    width: 500px;
    height: 67px;
  }

  .checkbox-round {
    width: 15px;
    height: 15px;
  }

  .headerbottom {
    height: 140px;
    width: 100%;
    flex-direction: row;
  }

  .headerbottomleft,
  .headerbottomright {
    width: 76.4%;
    padding: 20px 100px;
  }

  .roundedsection img {
    height: 93px;
    width: 93px;
  }

  .mobiledes h5 {
    font-size: 24px;
    margin-top: 0;
  }

  .mobiledes p {
    font-size: 15px;
  }

  .headertitleimg {
    width: 80%;
  }
}

/* Styles for screens between 480px and 960px */
@media (min-width: 480px) and (max-width: 960px) {
  .contain .headermain {
    display: flex;
    flex-direction: row;
    height: 380px;
  }
  .homebgimage::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 128%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    );
    opacity: 0.6;
    z-index: 1;
  }
  .fa-cube {
    margin-left: -8px;
    margin-top: -5px;
  }
  .fa-newspaper {
    margin-left: -2px;
    margin-top: -5px;
  }
  .homebgimage img {
    width: 128%;
    height: 100%;
    object-fit: cover;
  }
  .homebgimage p {
    margin-left: 30px;
  }

  .header {
    font-size: 28px;
    text-align: center;
    margin: 20px 0;
    line-height: 36px;
  }

  .table-headerright {
    margin: 0;
    margin-bottom: 10px;
  }

  .headerleftbox {
    width: 360px;
    height: 60px;
  }

  .checkbox-round {
    width: 25px;
    height: 19px;
    border-radius: 50%;
  }

  .headerbottom {
    height: auto;
    width: 100%;
    flex-direction: row;
  }

  .headerbottomleft,
  .headerbottomright {
    width: 100%;
    padding: 20px;
    height: 110px;
  }

  .roundedsection img {
    height: 80px;
    width: 80px;
  }

  .mobiledes h5 {
    font-size: 21px;
  }
  .header-right h3 {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: -15px;
  }

  .mobiledes p {
    font-size: 14px;
  }

  .headertitleimg {
    width: 70%;
  }
}
@media (min-width: 961px) and (max-width: 1280px) {
  .header-right::before {
    top: 20px;
    left: -10px; /* Burayı uygun şekilde ayarlayabilirsiniz */
    right: -5px;
    bottom: 10px;
  }

  .header-right h3 {
    font-size: 25px;
    line-height: 35px;
    margin-top: 30px;
  }
  .table-headerright {
    padding-bottom: 10px;
  }

  .header {
    font-size: 32px;
    text-align: center;
    margin: 20px 0;
    line-height: 40px;
  }

  .homebgimage img {
    width: 100%;
    height: auto;
  }

  .headerleftbox {
    width: 420px;
    height: 65px;
  }

  .checkbox-round {
    width: 20px;
    height: 20px;
  }

  .headerbottom {
    height: auto;
    width: 100%;
    flex-direction: row;
  }

  .headerbottomleft,
  .headerbottomright {
    width: 100%;
    padding: 20px;
    height: 120px;
  }

  .roundedsection img {
    height: 85px;
    width: 85px;
  }

  .mobiledes h5 {
    font-size: 20px;
  }

  .mobiledes p {
    font-size: 15px;
  }

  .headertitleimg {
    font-size: 35px;
    width: 80%;
  }
}
