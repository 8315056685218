.reference {
  height: 244px;
  width: 100%;
  background-color: #858b6777;
  padding: 30px 200px 30px 110px !important;
}
.referencetitle {
  font-size: 50px;
  font-weight: 700;
  line-height: 61px;
  color: var(--main-color);
}
.hrref {
  width: 1268px;
  height: 3px;
  color: rgb(97, 96, 96);
}
.reficon i {
  font-size: 28px;
  color: var(--main-color);
}
.reficon {
  margin-right: -70px;
}
@media (max-width: 480px) {
  .referencetitle {
    margin-left: -100px;
  }
  .hrref {
    margin-left: -100px;
    width: 270px;
  }
  .slider-container {
    width: 300px !important;
    margin-left: -80px;
  }
  .learnfinity {
    width: 150px !important;
    height: 50px !important;
  }
  .atomyum {
    width: 150px !important;
    height: 50px !important;
  }
  .juniustech {
    width: 60px !important;
    height: 70px !important;
  }
}
@media (min-width: 480px) and (max-width: 960px) {
  .reference {
    padding: 30px 80px 30px 50px !important;
  }

  .referencetitle {
    font-size: 40px;
    line-height: 50px;
  }

  .hrref {
    width: 100%;
    margin: 10px 0;
  }

  .slider-container {
    width: 100% !important;
    margin: 0;
  }

  .learnfinity,
  .atomyum {
    width: 100px !important;
    height: auto !important;
  }

  .juniustech {
    width: 40px !important;
    height: 53px !important;
  }
}
