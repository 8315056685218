.footer {
  color: #ffffff;
}
.footersection {
  background-color: var(--main-color);
  padding-top: 40px;
}
.footercopyright {
  background-color: var(--main-color);
  border-top: 1px solid white;
}
.footerfirst {
  gap: 40px;
}
.footeraddress p,
span {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}
.footerlogo {
  margin-bottom: 20px;
}
.socialfooter:hover {
  color: var(--orange-color) !important;
  scale: 1.2;
}
@media (max-width: 480px) {
  .footercontent div {
    margin-left: 30px;
  }
  .mobilef {
    margin-left: 0px !important;
  }
  .footeraddress {
    margin-left: 0px !important;
  }
  .adresp {
    margin-left: 30px;
  }
}
/* Add this to your existing CSS */

@media (min-width: 480px) and (max-width: 960px) {
  .footercontent {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footercontent div {
    margin-left: 20px;
    margin-right: 20px;
  }
  .footeraddress {
    margin-left: 20px;
  }
  .adresp {
    margin-left: 20px;
  }
  .mobilef {
    margin-left: 20px;
  }
  .footerlogo {
    width: 200px;
    height: 33px;
  }
  .footeraddress p, span {
    font-size: 14px;
    line-height: 16px;
  }
  .socialfooter {
    width: 20px;
    height: 20px;
  }
  .mb-4.text-decoration-none.text-lg.mt-md-3 {
    margin-left: 20px;}
  .d-flex.flex-column.mt-2 {
    margin-left: 40px;}
}