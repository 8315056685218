.navbar-top {
  background-color: #858b679d !important;
  height: 48px;
}

.social-wrapper {
  padding: 30px 50px;
  transition: all ease 0.5s;
}
.social-wrapper:hover {
  transform: scale(1.2);
  transition: all ease 0.5s;
}
.social-wrapper a {
  font-size: 10px;
}
.social-wrapper a:hover {
  color: var(--light-orange-color) !important;
}

.social {
  display: inline-block;
  margin: 0 15px;
  transition: transform 0.05s;
}
.sociali:hover {
  color: var(--light-orange-color);
}
.sociali {
  color: var(--main-color);
}
.social-wrapper a:visited {
  color: white !important;
}

.social:hover {
  transform: scale(1.1);
}
.dropdown-menu {
  z-index: 1055;
}
@media (max-width: 480px) {
  .navbar-top {
    background-color: #b4b8a1 !important;
    height: 28px;
    z-index: 1054 !important;
    border-bottom: none; /* Alt sınır kaldırılır */
  }
  .navbarnavwrap {
    margin-top: -45px !important;
    height: 28px !important;
  }

  .social-wrapper {
    display: flex;
    padding: 0;
    font-size: 20px;
    margin: 0 10px 0 10px;
  }
  .social-wrapper a {
    margin: 5px;
  }
  .mobileres {
    margin-top: 0 !important;
    padding: 0 !important;
    border: none !important;
  }

  .mobile-nav {
    display: none;
    width: 407px !important;
  }

  .navbar-toggle {
    display: none !important; /* Burger menüyü gizle */
  }

  .navbar-collapse {
    overflow: visible !important;
    display: block !important; /* Collapse olan menüyü görünür yapıyoruz */
    width: 407px !important;
  }
  #responsive-navbar-nav {
    border-top: none !important;
    border-bottom: none !important;
    background: transparent !important;
  }

  .dropdown {
    margin-left: 10px;
    z-index: 9999999999 !important;
  }
  .dropdown-menu {
    position: absolute !important;
  }
}
@media (min-width: 480px) and (max-width: 960px) {
  .navbar-top {
    background-color: #858b679d !important;
    height: 28px;
    z-index: 1021;
  }
  .social-wrapper {
    display: flex;
    padding: 0;
    font-size: 20px;
    margin: 0 20px 0 20px;
  }
  .social-wrapper a {
    font-size: 15px;
    margin-top: 15px;
  }
  .mobileres {
    margin-top: -60px;
  }
  .mobile-nav {
    width: 407px !important;
  }
  .dropdown-menu {
    z-index: 999;
    position: absolute !important;
    margin-left: 20px !important;
  }
  .dropdown-menulogin {
    z-index: 999;
    position: absolute !important;
    margin-left: 100px !important;
  }
  .dropdown-menuregister {
    z-index: 999;
    position: absolute !important;
    margin-left: 0 !important;
  }

  .navbar-toggle {
    display: none !important; /* Burger menüyü gizle */
  }

  .navbar-collapse {
    display: block !important; /* Normal menüyü göster */
    width: 407px !important;
  }
  .dropdown {
    margin-left: 10px;
  }
  .btnwrp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px !important;
    margin-top: 20px;
  }
}
