.registerBaslık {
  display: flex;
  justify-content: space-between;
}

.registeruser {
  background: rgba(255, 255, 255, 0.255) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.registerlawyer {
  background: rgba(255, 255, 255, 0.255) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.modal-content {
  border-radius: 20px !important;
  width: 100% !important;
  color: var(--main-color);
}
.modal-content label {
  color: var(--main-color);
}

.registerBaslık h1 {
  margin: 4%;
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  #registerRowDiv {
    display: block;
    text-align: center;
  }
}

.lawyerRegisterFormDiv {
  height: 85vh;
  width: 100%;
  padding: 1rem;
  overflow: auto;
  border-radius: 15px;
  color: #00242e;
}

.UserRegisterFormDiv {
  height: 54vh;
  width: 100%;
  padding: 2rem;
  overflow: auto;
  color: #00242e;
}
.UserRegisterFormDiv span {
  color: var(--main-color);
}

.registerFormDiv span {
  color: var(--main-color);
}

.registerLabel {
  width: 100%;
  margin-left: 2%;
  color: rgb(50, 47, 47);
}
.registerFormControl {
  padding: 3px 6px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 29%, 30%);
  margin: 3% 0;
}

.registerSelect {
  height: 31.77px !important;
  margin-top: 2.8%;
  border: 1px solid hsl(0, 0%, 29%, 30%);
  border-radius: 5px;
}
.registerSelectuser {
  margin-top: 2.9%;
  margin-right: 2%;
  height: 4.72vh;
  border: 1px solid hsl(0, 0%, 29%, 30%);
  border-radius: 5px;
}
.registerBranchSelect {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 3%;
  height: 38%;
  border: 1px solid hsl(0, 0%, 29%, 30%);
  border-radius: 5px;
}

.registerTelDiv {
  gap: 0.5rem;
  /* height: 15%; */
}

.registerCheckBtn {
  margin: 4% 0;
}
.registerCheckBtn label {
  font-size: 10px;
  margin-left: 2%;
}

.registerCheckBtn span {
  color: var(--main-color);
  font-size: 10px;
}

.registerButtons {
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 2rem;
  font: normal normal bold 18px/21px SF Pro Text;
}

.registerButtons button {
  padding: 5px;
  border-radius: 4px;
  width: 25%;
}
.registerbtn1 {
  border: 1px solid black;
  font-size: 1.2rem;
  font-weight: 900;
}
.registerbtn2 {
  border: none;
  background: var(--main-color) 0% 0% no-repeat padding-box;
  color: #f5f5f5;
}
.registerFormDiv p {
  text-align: center;
}

.UserRegisterFormDiv p {
  text-align: center;
}
.modal-90w {
  max-width: 42% !important;
}
.registerNewBtn {
  background-color: transparent !important;
}
@media (max-width: 480px) {
  .modal-90w {
    max-width: 80% !important;
    margin-left: 40px !important;
  }
  .kisiselveriler {
    display: flex;
  }
  .registerButtons {
    width: 400px !important;
    margin-left: -60px;
  }
  .registerBranchSelect {
    width: 100%;
    height: 30px !important;
    border: 1px solid hsl(0, 0%, 29%, 30%);
    border-radius: 5px;
  }
  .registerFormControl {
    width: 100%;
    height: 30px !important;
    border-radius: 5px;
    border: 1px solid hsl(0, 0%, 29%, 30%);
  }
}
