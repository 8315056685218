/* services.css */
.services {
  background-color: #a7c6d86e;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 632px;
}

.serviceElements {
  display: flex;
  flex-wrap: wrap; /* Add this line to allow wrapping of cards */
  gap: 30px;
  margin-left: 450px; /* Adjust the value as needed */
}

.serviceCard {
  width: 220px;
  height: 260px;
  border: 1px solid var(--main-color);
  margin-top: -70px;
  padding: 10px;
  background-color: #a7c6d818 !important;
  position: relative; /* Ensure the position is relative for absolute positioning inside */
}

.serviceCard p {
  font-size: 17px;
  font-weight: 400;
  color: var(--main-color);
  margin-bottom: 50px;
  padding: 0 5px;
}

.serviceCard h1 {
  font-size: 25px;
  font-weight: 700;
  color: var(--main-color);
  padding-top: 0;
}

.serviceIcons {
  background-color: var(--main-color);
  height: 11rem;
  width: 11rem;
  margin-top: 24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  color: white;
}

.servicetitle {
  font-size: 50px;
  font-weight: 700;
  line-height: 61px;
  color: var(--main-color);
}

.hrservice {
  width: 1268px;
  height: 3px;
  color: var(--main-color);
}

.serviceicon i {
  font-size: 28px;
  color: var(--main-color);
}

.serviceicon {
  margin-right: 35px;
}

.servicetop {
  padding: 140px 100px 20px 100px !important;
}

.servicesubtitle {
  font-size: 28px;
  color: var(--main-color);
  font-weight: 400;
  line-height: 35px;
  width: 25%;
}

@media only screen and (max-width: 480px) {
  .services {
    height: auto;
    padding: 20px;
    background-size: contain;
    background-color: #a7c6d86e;
  }

  .serviceElements {
    flex-direction: column;
    gap: 15px;
    margin-left: 0;
    align-items: center;
  }

  .serviceCard {
    width: 90%;
    height: auto;
    margin: 10px 0;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visual separation */
  }

  .serviceCard p {
    font-size: 14px;
    margin-bottom: 20px;
    padding: 0;
  }

  .serviceCard h1 {
    font-size: 20px;
    padding-top: 10px;
  }

  .serviceIcons {
    height: 65px;
    width: 65px;
    margin-top: 130px;
    position: relative;
  }
  .serviceIcons svg {
    font-size: 50px !important;
  }

  .servicetitle {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    margin-right: 30px;
  }

  .hrservice {
    width: 100%;
    height: 2px;
  }

  .serviceicon {
    margin-right: 0;
    justify-content: center;
  }

  .serviceicon i {
    font-size: 24px;
  }

  .servicetop {
    padding: 20px !important;
    text-align: center;
  }

  .servicesubtitle {
    font-size: 18px;
    width: 100%;
    margin-top: 10px;
  }
}
/* Existing styles */

@media only screen and (max-width: 960px) and (min-width: 481px) {
  .services {
    height: auto;
    padding: 40px 20px 50px 20px;
    background-size: contain;
    background-color: #a7c6d859;
  }

  .serviceElements {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0;
    justify-content: center;
  }

  .serviceCard {
    width: 350px;
    height: auto;
    margin-top: 20px;
    padding: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .serviceCard p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .serviceCard h1 {
    font-size: 22px;
    padding-top: 10px;
  }

  .serviceIcons {
    height: 80px;
    width: 80px;
    margin-top: 140px;
    position: relative;
  }

  .serviceIcons svg {
    font-size: 55px !important;
  }

  .servicetitle {
    font-size: 42px;
    line-height: 48px;
    text-align: center;
    margin-right: 0;
  }

  .hrservice {
    width: 100%;
    height: 2px;
  }

  .serviceicon {
    margin-right: 0;
    justify-content: center; /* Center icon buttons */
  }

  .serviceicon i {
    font-size: 26px; /* Adjust icon size */
  }

  .servicetop {
    padding: 40px 20px; /* Adjust padding */
    text-align: center; /* Center text */
  }

  .servicesubtitle {
    font-size: 20px; /* Adjust subtitle size */
    width: 100%;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 961px) {
  .serviceElements {
    justify-content: flex-start; /* Kartları sola hizala */
    gap: 20px; /* Kartlar arasındaki boşluğu ayarla */
    margin-left: 0;
    padding: 0 15px; /* Sağ ve sol tarafta 15px boşluk bırak */
  }

  .serviceCard {
    width: 296px; /* Kart genişliğini ayarla */
    height: auto;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .serviceCard p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .serviceCard h1 {
    font-size: 22px;
    padding-top: 10px;
  }

  .serviceIcons {
    height: 80px;
    width: 80px;
    margin-top: 180px;
    position: relative;
  }

  .serviceIcons svg {
    font-size: 55px !important;
  }

  .servicetitle {
    font-size: 42px;
    line-height: 48px;
    text-align: center;
    margin-right: 0;
  }

  .hrservice {
    width: 100%;
    height: 2px;
  }

  .serviceicon {
    margin-right: 0;
    justify-content: center;
  }

  .serviceicon i {
    font-size: 26px;
  }

  .servicetop {
    padding: 40px 15px; /* Sağ ve sol tarafta 15px boşluk bırak */
    text-align: center;
  }

  .servicesubtitle {
    font-size: 20px;
    width: 100%;
    margin-top: 10px;
  }
}
