.faq {
  width: 100%;
  background-color: #a7c6d859;
  padding: 30px 200px 30px 110px !important;
}
.faqtitle {
  font-size: 50px;
  font-weight: 700;
  line-height: 61px;
  color: var(--main-color);
}
.hrfaq {
  width: 1268px;
  height: 3px;
  color: var(--main-color);
}
.wrapper {
  border-bottom: 1px solid black;
  overflow: hidden;
}

.wrapper .question-container {
  width: 100%;
  text-align: left;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.question-container.active {
  color: var(--main-color);

  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.04),
    transparent
  );
}

.wrapper .question-container:hover {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.04),
    transparent
  );
}

.wrapper .arrow {
  font-size: 2rem;
  transition: 0.5s ease-in-out;
  color: var(--main-color);
}

.arrow.active {
  rotate: 180deg;
  color: var(--main-color);
}

.wrapper .answer-container {
  padding: 0 1rem;
  transition: height 0.7s ease-in-out;
}

.wrapper .answer-content {
  padding: 1rem 0;
  font-size: 20px;
  font-style: italic;
}
@media (max-width: 480px) {
  .faq {
    width: 408px !important;
  }
  .faqcontainer {
    width: 360px !important;
  }
  .faqtitle {
    font-size: 30px;
    width: max-content !important;
    margin-left: -90px;
  }
  .mobileaccordion {
    display: flex;
    flex-direction: column;
    margin-left: -110px;
    width: 360px !important;
  }
  .hrfaq {
    margin-left: -90px;
    width: 300px;
  }
}
@media (min-width: 480px) and (max-width: 960px) {
  .faqcontainer {
    width: 100%;
  }

  .faqtitle {
    font-size: 40px;
    margin-left: 0;
  }

  .mobileaccordion {
    display: flex;
    flex-direction: column;
    width: 120%; /* Ensure accordion takes full width */
    margin-left: 0; /* Reset margin for proper alignment */
  }

  .hrfaq {
    width: calc(100% - 100px); /* Adjust hr width according to padding */
    margin-left: 0; /* Reset margin for alignment */
  }
}
