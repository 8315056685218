.signInForm {
  justify-content: space-between;
  font-size: 1.3rem;
  padding: 3% 7%;
  width: 100%;
  border-radius: 10px;
  color: var(--main-color);
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.255) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.modal-content {
  background: rgba(255, 255, 255, 0.355) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.signInForm label {
  color: var(--main-color);
}

.vsc-initialized.modal-open {
  border: 3px solid red;
}

.signInForm span {
  color: var(--main-color);
}

.signInForm button {
  background-color: var(--main-color) !important;
  font: normal normal normal 17px/17px SF Pro Text !important;
  padding: 10px 0;
}

#userLoginModal .modal-content {
  background-color: #ffffff77;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
@media (max-width: 480px) {
  #userLoginModal {
    width: 140% !important;
    margin-left: -20% !important;
  }
}
