@import "../index.css";

* {
  font-family: "Gilroy, sans-serif";
}

.videosection {
  margin-top: 20px;
  max-width: 1380px;
  height: 405px;
}

.videomain {
  padding: 120px 100px;
  background-color: #858b679d;
}

.videoh2 {
  font-size: 45px;
  font-weight: 700;
  line-height: 55px;
  color: var(--main-color);
}

.videop {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  color: #00242e;
}

/* Responsive Styles */
@media (max-width: 960px) {
  .videosection {
    width: 100%;
    height: auto;
    margin-top: 50px;
  }
  
  .videomain {
    padding: 40px 20px;
   
  }

  .videoh2 {
    font-size: 30px;
    line-height: 40px;
  }

  .videop {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 10px;
    
  }
  
  .video-container video {
    width: 100%;
  }
}
