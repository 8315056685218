.admindashbord-container {
  width: 90% !important;
}
/* Sidebar menu items */
.adminleftbox-li {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 16px;
  color: #6c757d;
  cursor: pointer;
}

/* Menu item icon styling */
.menu-icon {
  margin-right: 10px;
  font-size: 18px;
}

/* Highlight selected menu item */
.adminleftbox-li.active,
.adminleftbox-li:hover {
  background-color: #e3f2fd;
  color: #1976d2;
  font-weight: bold;
}
