.comment {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.2px);
  -webkit-backdrop-filter: blur(1.2px);
  border: 1px solid rgba(255, 255, 255, 0);
}
.comment-star {
  color: #a97900;
  /* background-color: white; */
  cursor: pointer;
  font-size: 4rem;

  size: 20rem;
  transition: transform 0.2s;
}
.comment-star:hover {
  transform: scale(1.2);
}
