/* RequestPage.css */
.container-fluid {
  width: 100%;
  height: 100%;
  position: relative;
}

.img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-inline {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Boşlukları ayarlamak için */
}
.datepickerinput {
  width: 305px !important;
  height: 40px !important;
  font-size: 15px !important;
}
/* RequestPage.css */
.react-datepicker {
  font-size: 0.9em;
  width: 16rem !important; /* Genel font boyutunu büyütün */
}

.react-datepicker__header {
  font-size: 12px;
  width: 100%;
  line-height: 1em;
}

.react-datepicker__month {
  font-size: 1.3em !important;
}

.react-datepicker__day {
  width: 1.2em; /* Gün kutularının genişliğini büyütün */
  height: 2em; /* Gün kutularının yüksekliğini büyütün */
  line-height: 3em; /* Gün kutularındaki metinleri ortalayın */
}

.react-datepicker__day-name {
  width: 1.5em;
  height: 2em;
  line-height: 3em;
  font-size: 12px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.2em;
}

.react-datepicker__time-container {
  background: #fff;
  border-left: 1px solid #e6e6e6;
  width: 170px !important;
}

.react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-box {
  width: 100% !important;
  overflow: hidden;
  text-align: center;
}
.react-datepicker__triangle {
  display: none; /* Üçgen gösterimi iptal */
}
.react-datepicker__time-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  cursor: pointer;
}

.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-list-item:hover {
  background-color: #f0f0f0;
}
.btn {
  background-color: var(--light-orange-color);
  color: var(--main-color);
  font-weight: bold;
}
.btn:hover {
  background-color: var(--orange-color);
  color: var(--main-color);
}
.datepickerinput {
  width: 152.5px !important;
}
