.mainblog {
  padding: 0 150px 0 150px;
  background-color: #858b679d;
}
.blogtitle {
  font-size: 50px;
  font-weight: 700;
  line-height: 61px;
  color: var(--main-color);
}
.hrblog {
  width: 1268px;
  height: 3px;
  color: var(--main-color);
}
.card {
  width: 350px !important;
  height: 500px !important;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 20px;
}

.card-image {
  width: 90% !important;
  height: auto !important;
  border-radius: 5px !important;
}

.card-content {
  padding: 15px;
  text-align: center;
}

.card-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.card-author {
  font-size: 2rem;
  color: #666;
  margin: 5px 0;
}

.card-description {
  font-size: 1.35rem;
  color: #333;
}
.card-description span {
  text-align: justify;
}
.card-button {
  background-color: var(--main-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5rem;
  margin-top: 30px;
  width: 100%;
}

.card-button:hover {
  background-color: var(--light-orange-color);
  color: var(--main-color);
  font-weight: bold;
}

@media (max-width: 480px) {
  .mainblog {
    padding: 0 20px 0 20px;
  }
  .card {
    width: 100% !important;
    height: auto !important;
    margin: 10px 0;
  }
  .card-title {
    font-size: 1.5rem;
  }
  .card-author {
    font-size: 1.2rem;
  }
  .card-description {
    font-size: 1rem;
  }
  .card-button {
    font-size: 1rem;
    padding: 5px 10px;
  }
  .cardwrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

@media (min-width: 480px) and (max-width: 960px) {
  .mainblog {
    padding: 0 20px;
    flex-wrap: wrap;
  }

  .card {
    width: 45% !important;
    height: 500px !important;
    margin: 5px;
  }
  .card-title {
    font-size: 23px;
  }
  .blogtitle {
    margin-left: 20px;
  }
  .card-button {
    padding: 5px 10px;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 961px) {
  .mainblog {
    padding: 0 15px; /* Sağ ve sol boşlukları ayarla */
    display: flex;
    justify-content: center; /* Ana blog alanını ortala */
  }

  .cardwrapper {
    display: flex;
    justify-content: center; /* Kartları ortala */
    flex-wrap: wrap;
    gap: 20px; /* Kartlar arasındaki boşluğu ayarla */
    width: 100%; /* Kart sarma alanının genişliği */
  }

  .card {
    width: 350px !important;
    height: 500px !important;
    margin: 10px; /* Kartlar arasındaki dış boşluk */
  }

  .card-title {
    font-size: 23px;
  }

  .blogtitle {
    margin-right: 1050px;
    margin-bottom: 30px;
  }

  .card-button {
    padding: 5px 10px;
  }
}
