@import "../index.css";
* {
  font-family: "Gilroy, sans-serif";
}
.payment-table {
  width: 100%;
  border-collapse: collapse;
}
.payment-table th,
.payment-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.payment-table th {
  background-color: #f2f2f2;
  text-align: left;
}
.payment-table tr:hover {
  background-color: #f1f1f1;
}
.payment-table th,
.payment-table td {
  padding: 12px 15px;
}
.payment-table th {
  background-color: #007bff;
  color: white;
}
