.lawyerdashbord-container {
  border: 1px solid #2d3657 !important;
  display: flex;
  margin-bottom: 169px;
  width: 82%;
  height: 820px;
  border-radius: 10px;
  opacity: 1;
}

.lawyerdashbord-leftcard {
  width: 23%;
}
.lawyerpersonaldetailFormDiv {
  width: 800px;
  margin-left: 10px;
  margin-right: 10px;
}

.lawyerdashbord-rightcard {
  /* height: 77%; */
  padding-top: 10px;
  /* margin: auto; */
}

.lawyerdashboard-datedetail {
  border: 1px solid #2d3657 !important;
  border-radius: 10px;
  width: 303px;
  height: 559px;
  margin-left: 20px;
}

.border-bottom {
  margin: 32px 30px 32px 30px;
  /* border-bottom: 20px solid #a97900; */
  border: 1px solid #2d3657 !important;
}
.lawyerdashboard-photo {
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border: 1px solid #2d3657;
  border-radius: 50%;
}
.lawyerdashboard-buttons {
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 2rem;
  font-weight: bold;
}
.lawyerdashboard-button-vazgec {
  background-color: #ffffff;
  color: #aeb9bc;
  width: 82px;
  height: 40px;
  border: 1px solid #00242e !important;
  border-radius: 8px;
  opacity: 1;
  margin-right: 14px;
}
.lawyerdashboard-button-kaydet {
  background-color: #2d3657 !important;
  color: white !important;
  width: 80px;
  height: 40px;
  top: 699px;
  left: 1000px;
  border-radius: 8px;
  border: none;
  opacity: 1;
  /* margin-left: 14px; */
}

.lawyerdashboardregisterBaslık {
  top: 160px;
  left: 808px;
  width: 162px;
  height: 24px;
  font-size: 20px;
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.lawyerdashboard-registerLabel {
  width: 90px;
  height: 21px;
  margin-top: 20px;
  margin-bottom: 12px;
}

.lawyerdashboard-registerFormControl {
  width: 274px;
  height: 40px;
  border: 1px solid #7f9196;
  border-radius: 5px;
  opacity: 1;
  font-size: 14px;
  padding-left: 5px;

  /* margin-right: 3px; */
}

.lawyeraccountsetting-input {
  width: 274px;
}
.lawyerdashboard-registerFormControl-phone {
  width: 212px;
  height: 40px;
  border: 1px solid #7f9196;
  border-radius: 5px;
  opacity: 1;
}

.lawyerdashboardFormDiv {
  width: 500px;
  border: 2px solid red;
}
.lawyerdashboardadrees {
  width: 350px;
  height: 40px;
}

.lawyerdashboard-button-ödeme {
  width: 171px;
  height: 40px;
  background: var(--unnamed-color-a97900) 0% 0% no-repeat padding-box;
  background: #2d3657 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-17) / var(--unnamed-line-spacing-20)
    var(--unnamed-font-family-sf-pro-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-f5f5f5);
  text-align: center;
  font: normal normal bold 17px/20px SF Pro Text;
  letter-spacing: 0px;
  color: #f5f5f5;
  opacity: 1;
}

.rounded-button .btn-switch {
  border-radius: 50px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7f9196;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2d3657 !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2d3657 !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 480px) {
  .lawyerdashbord-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95% !important;
    border-radius: 10px;
    opacity: 1;
  }
  .lawyerdashbord-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto !important;
    width: 95% !important;
    border-radius: 10px;
    opacity: 1;
  }

  .lawyerdashboardpersonaldetail {
    width: 95% !important;
  }

  .lawyerdashbord-leftcard {
    width: 83%;
  }
  .lawyerdashbord-leftcard2 {
    width: 100% !important;
  }
  .lawyerdashboardabout {
    width: 90% !important;
  }
  .lawyerpersonaldetailFormDiv {
    width: 100% !important;
  }

  .search-left-border {
    display: none !important;
  }
  .userDashboardRight {
    width: 90% !important;
  }
  .deleteAccount {
    margin-left: -120px;
    margin-top: 20px;
  }
  .tablerandevular {
    display: flex;
    flex-direction: column;
  }
  .tablerandevular thead {
    width: 100% !important;
  }
  .tablerandevular tbody {
    width: 100% !important;
  }
  .tablerandevular-tarih {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    margin-right: -20px;
  }
}
@media (min-width: 480px) and (max-width: 960px) {
  .lawyerdashbord-container {
    display: flex;
    width: 95% !important;
    border-radius: 10px;
    opacity: 1;
  }
  .userDashboardRight {
    width: 100% !important;
  }
}
