.menu-link {
  text-transform: uppercase;
  font-weight: bold !important;
  color: white !important;
  padding: 20px !important;
  font-size: 17px !important;
  margin: 0;
}

.menu-link:hover {
  color: white !important;
  text-decoration: none !important;
}
.menulogo2.hidden {
  display: none !important;
}

@media (max-width: 480px) {
  .navbar-toggler {
    background-color: white !important;
    height: 37px;
    position: absolute;
    top: 5px;
    right: 10px;
    order: 0;
    z-index: 1056 !important;
  }
  .navbar {
    border: 1px solid var(--main-color) !important;
  }
  .navbar-nav {
    margin: 0 !important; /* Nav menüdeki margin kaldırılır */
    padding: 0 !important; /* Nav menüdeki padding kaldırılır */
    border: none !important; /* Border kaldırılır */
    box-shadow: none !important; /* Box-shadow kaldırılır */
  }

  .sol3 {
    margin-bottom: -70px !important;
  }
  .sag3 {
    margin-left: -9px;
  }

  .menulogo {
    margin-left: 10px;
    margin-top: 10px;
    order: 1;
  }

  .menulogo img {
    display: block;
    width: 260px;
    height: auto;
  }
  .menulogo2 {
    display: block !important;
    position: absolute;
    left: -5px;
    bottom: 0;
  }

  .navbar-collapse {
    max-height: 50px;
    overflow-y: hidden;
    transition: max-height 0.3s ease;
    border: none !important;
  }

  .navbar-collapse.show {
    max-height: 300px !important;
    overflow-y: auto;
    border: 1px solid transparent;
  }

  .navbar-collapse .menu-link {
    display: none;
    text-align: left;
    padding: 10px 0 !important;
    width: 100%;
    font-size: 14px !important;
  }

  .navbar-collapse.show .menu-link {
    display: block;
    margin-bottom: 2px;
  }

  #basic-navbar-nav {
    border: none !important;
    margin-left: -80px;
  }

  #basic-navbar-nav div {
    display: flex !important;
    flex-direction: column !important;
    gap: 2px !important;
    border: 1px solid var(--main-color) !important;
  }

  .altı {
    background-color: var(--main-color);
    display: none !important;
  }

  #basic-navbar-nav .menu-link:nth-child(3) {
    margin-bottom: 2px;
  }

  .menulogo img {
    display: none !important;
  }
}

@media (min-width: 481px) and (max-width: 960px) {
  .navbar-toggler {
    background-color: white !important;
    height: 40px;
    position: absolute;
    top: 7px;
    right: 10px;
    order: 0;
  }

  .menulogo2 {
    display: block !important;
    position: absolute;
    left: 50px;
    bottom: 0px;
  }

  .sol3 {
    margin-bottom: -70px !important;
  }

  .menulogo {
    margin-left: 10px;
    margin-top: 10px;
    order: 1;
  }

  .menulogo img {
    display: block;
    width: 260px;
    height: auto;
  }

  .navbar-collapse {
    max-height: 50px;
    overflow-y: hidden;
    transition: max-height 0.3s ease;
    display: block; /* Menü kapalıyken görünür olacak */
  }

  .navbar-collapse.show {
    max-height: 300px !important;
    overflow-y: auto;
  }

  .navbar-collapse .menu-link {
    display: none;
    text-align: left;
    padding: 10px 0 !important;
    width: 100%;
    font-size: 14px !important;
  }

  .navbar-collapse.show .menu-link {
    display: block;
    margin-bottom: 2px;
  }

  #basic-navbar-nav {
    border: none !important;
    margin-left: -80px;
  }

  #basic-navbar-nav div {
    display: flex !important;
    flex-direction: column !important;
    gap: 2px !important;
    border: none !important;
  }

  #basic-navbar-nav .menu-link:nth-child(3) {
    margin-bottom: 2px;
  }

  .menulogo img {
    display: none !important;
  }
}
