.card {
  width: 320px;
  height: 400px;
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #2d36571e;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.card img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 10px;
}

.card .details {
  margin: 10px;
}

.card .details h2 {
  font-weight: 600;
}

.card .details p {
  text-transform: uppercase;
  font-weight: 300;
}
#info {
  text-align: justify;
}
