* {
  font-size: 1.5rem;
}

.search-card-container {
  margin: auto;
  width: 100rem;
}

.search-card-lawyer {
  width: 100%;
  height: fit-content;
  border: 1px solid #2d3657;
  padding: 1rem 0 0 1rem;
}

.card-container button {
  font-size: 1rem;
  padding: 0 1rem;
}

.search-active {
  background-color: brown !important;
  color: yellow !important;
  border: none;
}

.button2 {
  width: 25rem;
  height: 4rem;
  font: rgb(24, 1, 1);
  font-size: 0.7rem;
  font-weight: bold;
  margin-left: 0;
  size: 1px;
}

.search-form {
  width: 50rem;
  height: 4rem;
  padding-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.8rem;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.081);
}

.search-select-input {
  display: flex;
  justify-content: center;
  width: 45rem;
  height: 4rem;
  border: none;
  padding-left: 0.8rem;
}

.search-inputbutton {
  background-color: var(--main-color);
  width: 10rem;
  height: 4rem;
  font: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 1rem;
}

.like {
  width: 3rem !important ;
  height: 3rem;
  background-color: white;
  border: none;
}
.tarih {
  width: 100%;
  font-size: 0.6rem;
  text-align: center;
}

.search-card-lawyer-rightbox {
  width: 35%;
  margin-left: 1rem;
}

.search-card-lawyer-leftbox {
  width: 55%;
}

.search-left-border {
  margin: 2rem 1rem 2rem 1rem;
  border-left: 1px solid #2d3657;
}

.star {
  color: var(--main-color);
}
.time {
  size: 2rem;
}

.much {
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  color: var(--main-color);
}
.search-caret {
  border: none;
  color: var(--main-color);
}

.extra-content {
  font-weight: 500;
}

.more {
  display: flex;
  justify-content: end;
  color: var(--main-color);
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 1rem;
}

.search-select {
  width: 25rem;
  justify-content: center;
  border-radius: 0.8rem;
  border: #f4f3ef;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.081);
}

.kayit {
  border: red;
  border-radius: 50px;
  border-width: 20px;
}

.search-hoursbutton {
  background-color: var(--main-color);
  width: 6rem;
  height: 2.2rem;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  border: none;
  border-radius: 1.2rem;
}
.selected {
  background-color: white;
  color: gray;
  text-decoration: line-through;
  border: 1px solid gray;
  cursor: none;
}
